import React from "react"

import { contacts } from "../common/contacts"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return  {
			blockName: "Часто задаваемые вопросы",
			title: "Что ещё рассказать?",
			questions: [
				{
					question: "Приложение бесплатно?",
					answer: (
						<div>
							Да, мобильное приложение для владельца ресторана Quick Resto Manager доступно на тарифах Смарт и Про.
						</div>
					),
				},
				{
					question: "Как быстро в приложение попадают данные о последних продажах?",
					answer: (
						<div>
							В&nbsp;течение нескольких секунд после продажи. Чтобы увидеть
							последние показатели, нужно обновить запущенное приложение, потянув
							экран вниз.
						</div>
					),
				},
				{
					question:
						"Зачем мне приложение, если есть раздел «Виджеты» в бэк-офисе с графиками?",
					answer: (
						<div>
							Приложение покажет ключевые показатели бизнеса на&nbsp;экране
							смартфона.
						</div>
					),
				},
				{
					question: "Какие данные я могу получать через пуш-уведомления?",
					answer: (
						<div>
							Сообщение о&nbsp;закрытии смены с&nbsp;суммой выручки, подозрительных
							операциях по&nbsp;кассе (отмена пречека, возврат чека), складских
							остатках, чеках с&nbsp;применением фиксированной скидки, а&nbsp;также
							отзывах, оставленных посетителями через приложение для гостей.
						</div>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return  {
			blockName: "Жиі қойылатын сұрақтар",
			title: "Тағы не айтуға болады?",
			questions: [
				{
					question: "Қосымша тегін бе?",
					answer: (
						<div>
							Иә, Quick Resto Manager мейрамхана иесіне арналған мобильді қосымшасы кез келген тарифте қолжетімді.
						</div>
					),
				},
				{
					question: "Қосымшаға соңғы сатулар туралы деректер қаншалықты тез түседі?",
					answer: (
						<div>
							Сатудан кейін бірнеше секунд ішінде. Соңғы көрсеткіштерді көру үшін, экранды төмен қарай
							тартып іске қосылған қосымшаны жаңарту керек.
						</div>
					),
				},
				{
					question:
						"Егер графиктер бар бэк-офисте «Виджеттер» бөлімі болса, маған қосымша не үшін керек?",
					answer: (
						<div>
							Қосымша смартфонның экранында бизнестің негізгі көрсеткіштерін көрсетеді.
						</div>
					),
				},
				{
					question: "Пуш-хабарламалар арқылы қандай деректерді ала аламын?",
					answer: (
						<div>
							Түсім сомасымене ауысымның жабылуы, касса бойынша күмәнді операциялар (пречекті болдырмау, чекті қайтару),
							қоймадағы қалдықтар, бекітілген жеңілдікті қолданумен чектер, сондай-ақ келушілер қонақтарға арналған қосымша
							арқылы қалдырған пікірлер туралы хабарлама.
						</div>
					),
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return  {
			blockName: "Часто задаваемые вопросы",
			title: "Что ещё рассказать?",
			questions: [
				{
					question: "Приложение бесплатно?",
					answer: (
						<div>
							Да, мобильное приложение для владельца ресторана Quick Resto Manager доступно на тарифах Смарт и Про.
						</div>
					),
				},
				{
					question: "Как быстро в приложение попадают данные о последних продажах?",
					answer: (
						<div>
							В&nbsp;течение нескольких секунд после продажи. Чтобы увидеть
							последние показатели, нужно обновить запущенное приложение, потянув
							экран вниз.
						</div>
					),
				},
				{
					question:
						"Зачем мне приложение, если есть раздел «Виджеты» в бэк-офисе с графиками?",
					answer: (
						<div>
							Приложение покажет ключевые показатели бизнеса на&nbsp;экране
							смартфона.
						</div>
					),
				},
				{
					question: "Какие данные я могу получать через пуш-уведомления?",
					answer: (
						<div>
							Сообщение о&nbsp;закрытии смены с&nbsp;суммой выручки, подозрительных
							операциях по&nbsp;кассе (отмена пречека, возврат чека), складских
							остатках, чеках с&nbsp;применением фиксированной скидки, а&nbsp;также
							отзывах, оставленных посетителями через приложение для гостей.
						</div>
					),
				},
			],
		}
	}

	return  {
		blockName: "Часто задаваемые вопросы",
		title: "Что рассказать еще?",
		questions: [
			{
				question: "Приложение бесплатно?",
				answer: (
					<div>
						Да, мобильное приложение для владельца ресторана Quick Resto Manager доступно на тарифах Смарт и Про.
					</div>
				),
			},
			{
				question: "Как быстро в приложение попадают данные о последних продажах?",
				answer: (
					<div>
						В&nbsp;течение нескольких секунд после продажи. Чтобы увидеть
						последние показатели, нужно обновить запущенное приложение, потянув
						экран вниз.
					</div>
				),
			},
			{
				question:
					"Зачем мне приложение, если есть раздел «Виджеты» в бэк-офисе с графиками?",
				answer: (
					<div>
						Приложение покажет ключевые показатели бизнеса на&nbsp;экране
						смартфона.
					</div>
				),
			},
			{
				question: "Какие данные я могу получать через пуш-уведомления?",
				answer: (
					<div>
						Сообщение о&nbsp;закрытии смены с&nbsp;суммой выручки, подозрительных
						операциях по&nbsp;кассе (отмена пречека, возврат чека), складских
						остатках, чеках с&nbsp;применением фиксированной скидки, а&nbsp;также
						отзывах, оставленных посетителями через приложение для гостей.
					</div>
				),
			},
		],
	}
}
